// NOTE can i extract this to a plugin?
import { camelCase } from 'lodash-es'

export const strapiGetRouteReference = (uid: string) => {
  let uidString = uid?.includes('.') ? uid?.split('.')?.pop() : uid
  uidString = camelCase(uidString)
  switch (uidString) {
    // 1
    case 'educationPage':
      return {
        name: 'education'
      }
    case 'educationEntry':
      return {
        name: 'education-slug',
        slug: true
      }

    // 2
    case 'eventsPage':
      return {
        name: 'events',
        slug: false
      }
    case 'eventEntry':
      return {
        name: 'events-slug',
        slug: true
      }

    // 3
    case 'exhibitionsPage':
      return {
        name: 'exhibitions',
        slug: false
      }
    case 'exhibitionEntry':
      return {
        name: 'exhibitions-slug',
        slug: true
      }

    // 4
    case 'homePage':
      return {
        name: 'index'
      }

    // 5
    case 'journalsPage':
      return {
        name: 'journals',
        slug: false
      }
    case 'journalEntry':
      return {
        name: 'journals-slug',
        slug: true
      }

    // 6
    case 'pageEntry':
      return {
        name: 'slug'
      }

    // 7
    case 'pressPage':
      return {
        name: 'press',
        slug: false
      }
    case 'pressEntry':
      return {
        name: 'press-slug',
        slug: true
      }

    // 8
    case 'projectsPage':
      return {
        name: 'projects',
        slug: false
      }
    case 'projectEntry':
      return {
        name: 'projects-slug',
        slug: true
      }

    default:
      return {
        name: 'index',
        slug: false
      }
  }
}
